@import '~antd/es/style/themes/default.less';

.main {
  height: 100vh;
  width: 368px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
  }
}
